import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Container from '../components/container'
import SEO from '../components/seo'

export default () => (
  <Layout>
    <SEO 
      title="About" 
      description="This is the about page description"
      keywords={['gatsby', 'about', 'patrick']}
    />
    <Container>
      <h1>Hi from the about page</h1>
      <p>Welcome to this page</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
)